$(document).ready(function() {

	$(document).on('click', 'a[href*=\\#]', function (e) {
		pos = $(this.hash);

		if($(pos).length){
			$('ul li').removeClass('current-menu-item');
			$(this).parent('li').addClass('current-menu-item');

			pos.addClass('targeted');

			$('html,body').animate({
				scrollTop: pos.offset().top -60
			}, 500);
			$(window).scroll();
			e.preventDefault();


			history.pushState({}, "", this.href);
		}
	});

});