$(document).ready(function() {
	if($('.gallery').length){
		jQuery( document ).ready(function() {
			var lightbox = '<div class="wysiwyg-gallery-lightbox"> <span class="wysiwyg-gallery-close cursor">&times;</span> <div class="wysiwyg-gallery-lightbox-content">';
			var buttons = '<a class="wysiwyg-gallery-prev">&#10094;</a><a class="wysiwyg-gallery-next">&#10095;</a>'
			var image = '';
			var images = '';
			var currentSlideImage = '';
			var currentSlideCaption = '';


			jQuery('.gallery').each(function(index){
				jQuery(this).addClass('wysiwyg-gallery');
				var last = jQuery(this).find('img').length;
				jQuery(this).find('.gallery-item').each(function(index){
					//jQuery(this).addClass('wysiwyg-gallery-item');
					$(this).addClass('wysiwyg-gallery-item');
					$(this).css('height', $(this).css('width'));
					$(this).children('.gallery-icon').addClass('wysiwyg-gallery-icon');
					$(this).find('img').addClass('wysiwyg-gallery-image');
					currentSlideCaption = jQuery(this).find('.gallery-caption').html();
					if (currentSlideCaption == undefined){
						currentSlideCaption = '';
					}
					currentSlideImage = jQuery(this).find('img');
					currentSlideImage.attr('data-wysiwyg-gallery-slide', index);

					images += '<img class="wysiwyg-gallery-slide-preview" src="'+currentSlideImage.attr('src')+'" data-wysiwyg-gallery-slide="'+index+'">'
					lightbox += '<div class="wysiwyg-gallery-slide"> <div class="wysiwyg-gallery-slide-numbertext"><span class="wysiwyg-gallery-currentslide-number">1</span> / '+last+'</div> <img src="'+currentSlideImage.attr('src')+'" class="wysiwyg-gallery-slide-image" style="width:100%" /> '
					lightbox += '<div class="wysiwyg-gallery-slide-caption-container"><p class="wysiwyg-gallery-slide-caption-content">'+currentSlideCaption+'</p></div></div>';
				});

				lightbox += buttons;
				lightbox += '<div class="column">';
				lightbox += images;
				lightbox += '</div></div></div>';


				jQuery(this).append(lightbox);
			});


			/*Functions*/


			function openModal() {
				//document.getElementById('myModal').style.display = "flex";
				jQuery('.wysiwyg-gallery-lightbox').css('display', 'flex');
				jQuery('html').addClass('wysiwyg-gallery-slide-open');
			}

			function closeModal() {
				jQuery('.wysiwyg-gallery-lightbox').css('display', 'none');
				jQuery('html').removeClass('wysiwyg-gallery-slide-open');
			}

			var slideIndex = 1;
			showSlides(slideIndex);

			function plusSlides(n) {
				//console.log('asd');
				showSlides(slideIndex += n);
			}

			function currentSlide(n) {
				showSlides(slideIndex = n);
			}

			function showSlides(n) {
				var i;
				var slides = jQuery('.wysiwyg-gallery-slide');
				var dots = jQuery('.wysiwyg-gallery-slide-preview');
				var captionText = jQuery('.caption');
				if (n > slides.length) {slideIndex = 1}
				if (n < 1) {slideIndex = slides.length}
				for (i = 0; i < slides.length; i++) {
					slides[i].style.display = "none";
				}
				for (i = 0; i < dots.length; i++) {
					dots[i].className = dots[i].className.replace(" active", "");
				}
				slides[slideIndex-1].style.display = "block";
				dots[slideIndex-1].className += " active";
				captionText.innerHTML = dots[slideIndex-1].alt;
				jQuery('.wysiwyg-gallery-currentslide-number').html(n);
			}

			/*Events*/


			jQuery('.gallery a').click(function(e){
				e.preventDefault();
				//console.log('gallery');
				// var $this = jQuery(this);
				// jQuery('.gallery a').removeClass('open');
				// $this.addClass('open');
			});

			//jQuery('.gallery img').click(openModal);
			jQuery('.gallery img').click(function(){
				var slide = jQuery(this).attr('data-wysiwyg-gallery-slide');
				slide = parseInt(slide);
				slide = slide + 1;
				//console.log(slide);
				openModal();
				currentSlide(slide);

			});
			jQuery('.wysiwyg-gallery-slide-preview').click(function(){
				var slide = jQuery(this).attr('data-wysiwyg-gallery-slide');
				slide = parseInt(slide) +1;
				currentSlide(slide);
			});
			/*jQuery('.wysiwyg-gallery-prev').click(plusSlides(-1));
			jQuery('.wysiwyg-gallery-next').click(plusSlides(1));*/
			jQuery('.wysiwyg-gallery-next').click(function () {
				plusSlides(1);
			});
			jQuery('.wysiwyg-gallery-prev').click(function () {
				plusSlides(-1);
			});
			jQuery('.wysiwyg-gallery-close').click(closeModal);
			jQuery(document).keydown(function(e) {
				switch(e.which) {
					case 37: // left
						plusSlides(1);
						break;

					case 39: // right
						plusSlides(-1);
						break;

					case 27: // right
						closeModal();
						break;

					default: return; // exit this handler for other keys
				}
				e.preventDefault(); // prevent the default action (scroll / move caret)
			});
		});
	}
});
