$(document).ready(function(){
	$('.menu-toggler').click(function (e) {
		e.preventDefault();
		$('html').toggleClass('menu-open');
	});

	$('.mobile #menu-main-menu a').click(function(){
		//$('.sub-menu-open').removeClass('sub-menu-open');
		//$('.sub-menu.open').removeClass('open');
	});

	$('.mobile #menu-main-menu .menu-item-has-children>a').click(function (e) {


		if($(this).parent('li.sub-menu-open').length < 1){

			e.preventDefault();
			$(this).next('.sub-menu').addClass('open');
			$(this).parent('li').addClass('sub-menu-open');

		}
	})
});