$(document).ready(function(){

	if($('.accordion').length){
		$('.panel').each(function(e){
			var height = $(this).height();
			$(this).parent().find('.collapse').css('max-height', '0');
		});

		$('.panel .head').on('click', function(){

			var $button = $(this);
			var $panel = $button.parent();
			var $collapse = $panel.find('.collapse');
			var $content = $panel.find('.content');
			var currentMaxHeight = parseInt($collapse.css('max-height').replace('px', ''));

			/*Close all Accordions*/
			//$('.accordion .collapse').css('max-height', '0px');
			//$('.accordion .panel').removeClass('open');
			//$('.accordion .content).attr('tabindex', '-1');
			//$('.accordion .collapse').attr('aria-hidden', 'true')
			//$('.accordion .head').attr('aria-expanded', 'false');

			if (currentMaxHeight > 0) {
				$collapse.css('max-height', '0px');
				$panel.removeClass('open');
				$content.attr('tabindex', '-1');
				$collapse.attr('aria-hidden', 'true');
				$button.attr('aria-expanded', 'false');
			}else{
				$collapse.css('max-height', $collapse.prop('scrollHeight')+'px')
				$panel.addClass('open');
				//$content.focus();
				$content.attr('tabindex', '0');
				$collapse.attr('aria-hidden', 'false');
				$button.attr('aria-expanded', 'true');
			}
		})
	}
})