$( document ).ready(function() {
	function navbg(){
		var scrollPos = $(document).scrollTop();
		var winHeight = $( window ).height() / 100 * 70;
		var alpha = 100 / winHeight * (scrollPos) / 100;
		if (alpha <= 100) {
			$('.has-slider header').css('background-color', 'rgba(37, 37, 37,'+alpha+')');
			$('.has-slider header .sub-menu').css('background-color', 'rgba(37, 37, 37,'+alpha+')');
		}
	}

	$(document).on('scroll', navbg);
	navbg();
});