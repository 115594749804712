$(document).ready(function(){
	$('.slider').slick({
		autoplay: true,
		dots: true,
		nextArrow: '<button type="button" class="slick-next"></button>',
		prevArrow: '<button type="button" class="slick-prev"></button>',
		autoplaySpeed: 4000,
		lazyLoad: 'ondemand',
		slidesToShow: 1,
	});
});