$( document ).ready(function() {

	function iframeWidth() {
		$('iframe').each(function (e) {
			$(this).closest('p').addClass('iframe');
			var width = $(this).closest('.iframe').width();
			var height = width/16*9;
			$(this).attr('width', width);
			$(this).attr('height', height);
		})
	}

	if($('iframe').length){
		iframeWidth();
		$(window).resize(iframeWidth());
	}

});