$(document).ready(function () {
	function mobile() {
		if ($(window).width() < 767) {
			$('html').addClass('mobile');
		}else{
			$('html').removeClass('mobile');
		}
	}

	$( window ).resize(mobile);
	mobile();
});